<template>
  <div class="c_pingbi_time_pop">
    <el-dialog title="新增黑名单" :close-on-click-modal="false" :visible.sync="show">
        <el-form size="small" :model="form" label-width="120px">
            <el-form-item label="选择平台:">
              <el-select v-model="form.platform_id" @change="platFormChange">
                <el-option v-for="item in shopTypeList" :key="item.id" :label="item.title" :value="item.id"/>
              </el-select>
            </el-form-item>
            <el-form-item label="选择店铺:">
              <el-select v-model="form.user_shop_id">
                <el-option v-for="item in storeList" :key="item.id" :label="item.title" :value="item.id"/>
              </el-select>
            </el-form-item>
            <el-form-item label="拉黑账号:">
              <el-row>
                <el-col :span="13">
                  <el-input placeholder="请输入旺旺号" v-model="form.account"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="拉黑时长:">
              <el-date-picker v-model="form.time" type="datetime" :picker-options="pickerOptions" placeholder="选择日期时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="拉黑原因:">
              <el-input placeholder="请输入拉黑原因" type="textarea" v-model="form.remark" :autosize="{ minRows: 4, maxRows: 10 }" maxlength="100" show-word-limit></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show = false">取 消</el-button>
          <el-button type="primary" @click="apiAddShopBlackHandel">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiAddShopBlack, apiGetShopList } from '@/utils/api.js'
export default {
   props: {
    shopTypeList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      storeList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < (Date.now() - 86400000);
        }
      },
      seeStatus:1,
      show: false,

      form: {
        platform_id: '',
        account: '',
        user_shop_id: '',
        end_time: '',
        time: '',
        remark: ''
      },
    }
  },
  methods: {
    // 选择平台筛选店铺
    platFormChange() {
      this.form.user_shop_id = ''
      this.storeList = []
      this.apiGetShopListHandel()
    },
    // 获取店铺列表
    async apiGetShopListHandel() {
      const { success, data } = await apiGetShopList({ status: 2, platform_id: this.form.platform_id , limit:100 })
      if(!success) return
      this.storeList = data.rows
      console.log(data)
    },
    
    // 加入黑名单
    async apiAddShopBlackHandel() {
      let loading = this.$loading()
      console.log(this.form.time)
      console.log(this.form.time.getTime())
      console.log(typeof this.form.time)
      if((typeof this.form.time) == 'object') this.form.end_time = parseInt(this.form.time.getTime() / 1000)
      else this.form.end_time = parseInt(this.form.time / 1000)
      const { success, data } = await apiAddShopBlack(this.form).catch(() => loading = false)
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.$emit('emitSaveSuccess')
      this.show = false
    },
    onShow() {
      this.show = true
    },
    onClose() {
      this.show = false
      this.form.user_shop_id = ''
      this.storeList = []
      this.form = {
        platform_id: '',
        account: '',
        user_shop_id: '',
        end_time: '',
        time: '',
        remark: ''
      }
    },
  }
}
</script>

<style lang='scss'>

</style>